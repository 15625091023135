.title {
    margin: 15px 5px 30px;

    font-size: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.section > div {
    margin-bottom: 15px;
}

.section h4 {
    margin-bottom: 5px;
}

.section ol {
    margin-left: 40px;
}

.section ol span:first-child {
    margin-right: 5px;
    text-decoration: underline;
}

.donationButtons {
    display: flex;
}

.donationButtons a {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 180px;
    min-height: 40px;
    width: 260px;
    height: 40px;
    padding: 9px 24px;

    color: white;
    text-decoration: none;

    background-color: #000;
    border-radius: 4px;

    transition: .2s background-color ease-in-out;

    cursor: pointer;
}

.donationButtons a:last-child {
    margin-left: 20px;
}

.donationButtons a:hover {
    background-color: #3c4043;
}

.donationButtons span {
    margin-top: 0;
}

.donationButtons img {
    width: 60px;
    margin-left: 10px;
}

@media (max-width: 600px) {
    .section {
        padding: 0 10px;
    }

    .donationButtons {
        flex-direction: column;
    }

    .donationButtons a:last-child {
        margin-left: 0;
        margin-top: 20px;
    }
}
