.item {
    position: relative;
    max-height: 600px;
    height: 100%;
}

.itemBG {
    position: relative;
    display: flex;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.itemBG img {
    z-index: -999;
    opacity: 0;
    object-fit: contain;
    max-height: 600px;
}

@media (max-width: 600px) {
    .itemBG img {
        max-height: 400px;
    }
}

@media (max-width: 500px) {
    .itemBG img {
        max-height: 250px;
    }
}

@media (max-width: 400px) {
    .itemBG img {
        max-height: 200px;
    }
}

.slider1 {
    /*background-image: url('./img/slider1.jpg');*/
}

.slider2 {
    background-image: url('./img/slider2.jpg');
}

.slider3 {
    background-image: url('./img/slider3.jpg');
}

.itemTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 1200px;
    width: 100%;
    font-size: 36px;
    font-weight: 700;

    text-align: center;
}

.itemTitle img {
    opacity: 1;
}

.titleWhite {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}

.titleBlack {
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
}

@media (max-width: 600px) {
    .itemTitle {
        font-size: 18px;
    }
}
